export const showLoading = (type, message) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_IS_LOADING",
      payload: true,
    });
  };
};

export const closeLoading = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_IS_LOADING",
      payload: false,
    });
  };
};

export const updateCurrentGuest = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_CURRENT_GUEST",
      payload: data,
    });
  };
};

export const updateSessionExpired = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_SESSION_EXPIRED",
      payload: data,
    });
  };
};

export const updatePlatform = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_PLATFORM",
      payload: data,
    });
  };
};
