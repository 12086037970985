import axios from "axios";
import {
  deleteCookie,
  deleteLocalStorage,
  getCookie,
  getLocalStorage,
} from "./general";

const tokenName = `${process.env.REACT_APP_ENVIRONMENT}qdweddingguest`;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((request) => {
  const sessionToken = getLocalStorage(tokenName) || getCookie(tokenName);

  if (sessionToken) {
    request.headers["Authorization"] = sessionToken;
  }

  return request;
});

api.interceptors.response.use((response) => {
  if (!response.data && window.location.pathname !== "/login") {
    deleteCookie(tokenName);
    deleteLocalStorage(tokenName);
    window.location.replace("/login");
  }

  return response;
});

// Auth APIs
export const loginApi = async (payload) => {
  const result = await api.post("/guest/login", payload).catch((_) => {
    return false;
  });

  return result;
};

export const verifySessionApi = async (payload) => {
  const result = await api.post("/guest/verify", payload).catch((_) => {
    return false;
  });

  return result;
};

// Guest APIs
export const guestSubmitApi = async (payload) => {
  const result = await api.post("/guest/submit", payload).catch((_) => {
    return false;
  });

  return result;
};

export const getGuestApi = async (payload) => {
  const result = await api.get("/guest", payload).catch((_) => {
    return false;
  });

  return result;
};
