import { useTranslation } from "react-i18next";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "../../redux/actions/notificationAction";

function Notification({ value, onSubmit }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appState = useSelector((state) => state.app);

  return (
    <div className="notification">
      <div className="space"></div>
      <div className="modal">
        <div className="modal__title">{t("63")}</div>
        <div className="modal__content">
          <div>
            <div className="label">{t("21")}</div>
            <div className="value">{value.name}</div>
          </div>
          <div>
            <div className="label">{t("22")}</div>
            <div className="value">{value.contactNo}</div>
          </div>
          <div>
            <div className="label">{t("23")}</div>
            <div className="value">{value.pax}</div>
          </div>
          <div>
            <div className="label">{t("25")}</div>
            <div className="value">
              {value.vegetarian === "Y" ? "Yes" : "No"}
            </div>
          </div>
          {appState.platform === "mobile" && (
            <div>
              <div className="label">{t("72")}</div>
              <div className="value">{value.vegetarianPax}</div>
            </div>
          )}
          <div>
            <div className="label">{t("73")}</div>
            <div className="value">
              {value.babyChair === "Y" ? "Yes" : "No"}
            </div>
          </div>
          {appState.platform === "web" && (
            <div>
              <div className="label">{t("72")}</div>
              <div className="value">{value.vegetarianPax}</div>
            </div>
          )}
          <div className="remarks">
            <div className="label">{t("28")}</div>
            <div className="value">{value.remarks}</div>
          </div>
        </div>
        <div style={{ flex: 1 }}></div>
        <div className="modal__divider"></div>
        <div className="modal__btn-group">
          <div className="btn btn--confirm" onClick={onSubmit}>
            {t("64")}
          </div>
          <div
            className="btn btn--cancel"
            onClick={(e) => {
              dispatch(closeNotification());
            }}
          >
            {t("65")}
          </div>
        </div>
      </div>
      <div className="space"></div>
    </div>
  );
}

export default Notification;
