import { combineReducers } from "redux";
import alertReducer from "./alertReducer";
import appReducer from "./appReducer";
import notificationReducer from "./notificationReducer";

const rootreducer = combineReducers({
  alert: alertReducer,
  app: appReducer,
  notification: notificationReducer,
});

export default rootreducer;
