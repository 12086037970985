import React, { useEffect } from "react";

function ImagePreloader({ images }) {
  useEffect(() => {
    const preloadImages = () => {
      images.forEach((imageSrc) => {
        const img = new Image();
        img.src = process.env.PUBLIC_URL + "/assets/img/" + imageSrc;
      });
    };

    preloadImages();
  }, [images]);

  return <></>;
}

export default ImagePreloader;
