import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { loginApi } from "../../utils/api";
import { useTranslation } from "react-i18next";
import { setCookie, setLocalStorage } from "../../utils/general";
import { closeLoading, showLoading } from "../../redux/actions/appAction";

const tokenName = `${process.env.REACT_APP_ENVIRONMENT}qdweddingguest`;

function Login() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const appState = useSelector((state) => state.app);
  const [authError, setAuthError] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [formError, setFormError] = useState({
    username: null,
    password: null,
  });

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCookie("locale", lang);
    setLocalStorage("locale", lang);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    dispatch(showLoading());

    const { data } = await loginApi({
      username: formData.username,
      password: formData.password,
    });

    if (!data) {
      setTimeout(() => {
        setAuthError(true);
        dispatch(closeLoading());
      }, 300);
      return;
    }

    setCookie(tokenName, data);
    setLocalStorage(tokenName, data);
    setTimeout(() => {
      window.location.replace("/");
    }, 500);
  };

  const handleFieldChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    setFormData({
      ...formData,
      [inputName]: inputValue,
    });

    if (formError[inputName] === undefined) return;

    if (!inputValue || inputValue === "") {
      setFormError({
        ...formError,
        [inputName]: true,
      });
    } else {
      setFormError({
        ...formError,
        [inputName]: false,
      });
    }
  };

  return (
    <div
      className="login"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/${
          appState.platform === "web" ? "qd_151.jpg" : "qd_151_mb.jpg"
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="login__content">
        <div className="login__language">
          <div className="login__language__logo">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/img/langs_icn_login.svg`}
            />
          </div>
          <div
            className="login__language__english"
            onClick={() => changeLanguage("en")}
          >
            <b>ENG</b>
          </div>
          <div className="login__language__divider"></div>
          <div
            className="login__language__chinese"
            onClick={() => changeLanguage("cn")}
          >
            中文
          </div>
        </div>
        <div className="login__content__img">
          <img
            className="web"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/qd_118.jpg`}
          />
          <img
            className="mobile"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/qd_118_mb.jpg`}
          />
        </div>
        <div className="login__content__form">
          <div className="login__content__form__logo">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/img/dq_logo.svg`}
            />
          </div>
          <div className="login__content__form__main">
            <div className="login__content__form__main--welcome">{t("1")}</div>
            <div className="login__content__form__main--title">{t("2")}</div>
            <form
              className="login__content__form__main--form"
              noValidate=""
              autoComplete="off"
            >
              <div className="form-field">
                <label>{t("3")}</label>
                <input
                  name="username"
                  type="text"
                  className="form-control"
                  value={formData.username}
                  onChange={handleFieldChange}
                  spellCheck={false}
                />
              </div>
              <div className="form-field">
                <label>{t("4")}</label>
                <input
                  name="password"
                  type="password"
                  autoComplete="off"
                  className="form-control"
                  value={formData.password}
                  onChange={handleFieldChange}
                />
              </div>
              {Object.values(formError).includes(true) ||
              Object.values(formError).includes(null) ? (
                <div className="submit-btn disabled">{t("5")}</div>
              ) : (
                <div className="submit-btn" onClick={onSubmit}>
                  {t("5")}
                </div>
              )}
            </form>
            <div className="login__content__form__main--err-msg">
              {authError && t("59")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
