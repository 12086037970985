import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./style.scss";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxPromise from "redux-promise";
import reduxThunk from "redux-thunk";
import rootReducer from "./redux/reducers/rootReducer";

import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { getCookie, getLocalStorage } from "./utils/general";

if (process.env.REACT_APP_ENVIRONMENT !== "local") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const locale = getLocalStorage("locale") || getCookie("locale");
const lng = ["en", "cn"].includes(locale) ? locale : "en";

i18n.init({
  interpolation: { escapeValue: false },
  lng,
  resources: {
    en: {
      translation: require("./locales/en.json"),
    },
    cn: {
      translation: require("./locales/cn.json"),
    },
  },
});

export const store = applyMiddleware(reduxPromise, reduxThunk)(createStore)(
  rootReducer
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </React.StrictMode>
  </I18nextProvider>
);
