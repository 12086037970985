import { useSelector } from "react-redux";
import "./style.scss";

function Spinner() {
  const appState = useSelector((state) => state.app);

  return appState.isLoading ? (
    <div className="spinner">
      <div className="spinner__item"></div>
    </div>
  ) : (
    <></>
  );
}

export default Spinner;
