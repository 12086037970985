import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Accordions from "../../components/Accordions";
import { useTranslation } from "react-i18next";
import withAuth from "../../hoc/withAuth";
import { getGuestApi, guestSubmitApi } from "../../utils/api";
import { closeLoading, showLoading } from "../../redux/actions/appAction";
import { setCookie, setLocalStorage } from "../../utils/general";
import Notification from "../../components/Notification";
import {
  closeNotification,
  showNotification,
} from "../../redux/actions/notificationAction";
import CountdownTimer from "../../components/CountdownTimer";

function Main() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const appState = useSelector((state) => state.app);
  const notificationState = useSelector((state) => state.notification);
  const rsvpRef = useRef(null);
  const eventDetailsRef = useRef(null);
  const faqRef = useRef(null);
  const seatingLayoutRef = useRef(null);
  const [rsvpFormData, setRsvpFormData] = useState({
    name: "",
    contactNo: "",
    remarks: "",
  });
  const [formError, setFormError] = useState({
    name: null,
    contactNo: null,
  });
  // const [dietary, setDietary] = useState({ label: t("57"), value: "N" });
  const [vegetarian, setVegetarian] = useState("N");
  const [babyChair, setBabyChair] = useState("N");
  const [pax, setPax] = useState(1);
  const [vegetarianPax, setVegetarianPax] = useState(0);
  const [status, setStatus] = useState("");
  const [rsvpInfo, setRsvpInfo] = useState({});
  const [maxPax, setMaxPax] = useState(1);

  // const dietaryList = [
  //   { label: t("57"), value: "N" },
  //   { label: t("25"), value: "V" },
  //   { label: t("26"), value: "H" },
  //   { label: t("27"), value: "SA" },
  // ];

  const faqList = [
    {
      title: t("42"),
      body: t("43"),
    },
    {
      title: t("44"),
      body: t("45"),
    },
    {
      title: t("46"),
      body: t("47"),
    },
    {
      title: t("48"),
      body: t("49"),
    },
    {
      title: t("50"),
      body: t("51"),
    },
  ];

  useEffect(() => {
    getGuestApi().then((res) => {
      const { status, maxPax } = res.data;

      if (status === "submitted") {
        setRsvpInfo(res.data);
      }

      setStatus(status);
      setMaxPax(maxPax);
    });
  }, []);

  const changeLanguage = (lang) => {
    const currentLang = localStorage.getItem("locale");

    if (currentLang === lang) return;

    setCookie("locale", lang);
    setLocalStorage("locale", lang);

    if (["accepted", "submitted"].includes(status)) {
      window.location.reload();
    } else {
      i18n.changeLanguage(lang);
    }
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleRsvpFieldChange = (event) => {
    const inputName = event.target.name;

    let value = "";

    if (inputName === "contactNo") {
      value = event.target.value.replace(/[^0-9]/g, "");
    } else {
      value = event.target.value;
    }

    setRsvpFormData({
      ...rsvpFormData,
      [inputName]: value,
    });

    if (formError[inputName] === undefined) return;

    if (!value || value === "") {
      setFormError({
        ...formError,
        [inputName]: true,
      });
    } else {
      setFormError({
        ...formError,
        [inputName]: false,
      });
    }
  };

  const handleTotalPaxChange = (op) => {
    if (op === "add") {
      if (pax < maxPax) setPax(pax + 1);
    } else if (op === "minus") {
      if (vegetarianPax === pax) {
        if (vegetarianPax > 1) setVegetarianPax(vegetarianPax - 1);
      }

      if (pax > 1) setPax(pax - 1);
    }
  };

  const handleVegetarianPaxChange = (op) => {
    if (op === "add") {
      if (vegetarianPax < pax) setVegetarianPax(vegetarianPax + 1);
    } else if (op === "minus") {
      if (vegetarianPax > 0) setVegetarianPax(vegetarianPax - 1);
    }
  };

  const onSubmitRsvp = async (e, answer) => {
    e.preventDefault();

    dispatch(showLoading());

    let info;

    if (answer === "submitted") {
      info = {
        ...rsvpFormData,
        pax,
        vegetarian,
        vegetarianPax,
        babyChair,
      };
    }

    const res = await guestSubmitApi({ status: answer, info });

    if (res.data.status === "submitted") {
      setRsvpInfo(res.data);
    }

    setStatus(res.data.status);
    dispatch(closeLoading());
    dispatch(closeNotification());
  };

  const handleOpenNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="main">
      <div className="main__language">
        <div className="main__language__logo">
          <img
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/langs_icn.svg`}
          />
        </div>
        <div
          className="main__language__english"
          onClick={() => changeLanguage("en")}
        >
          <b>ENG</b>
        </div>
        <div className="main__language__divider"></div>
        <div
          className="main__language__chinese"
          onClick={() => changeLanguage("cn")}
        >
          中文
        </div>
      </div>
      <section className="main__top">
        <div className="main__top__bg-img">
          <img
            className="web"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/dq_bg_1.jpg`}
          />
          <img
            className="mobile"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/dq_bg_1_mb.jpg`}
          />
        </div>
        <div className="main__top__content">
          <div className="main__top__content__logo">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/img/dq_logo_2.svg`}
            />
          </div>
          <div className="main__top__content__welcome">{t("1")}</div>
          <div className="main__top__content__sentence">{t("7")}</div>
          <div className="main__top__content__divider">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/img/group_79.svg`}
            />
          </div>
          <div className="main__top__content__sentence">{t("8")}</div>
          <div className="main__top__content__btn-group">
            <div onClick={() => scrollToSection(rsvpRef)}>{t("9")}</div>
            <div onClick={() => scrollToSection(eventDetailsRef)}>
              {t("10")}
            </div>
            <div onClick={() => scrollToSection(faqRef)}>{t("11")}</div>
          </div>
        </div>
      </section>
      <section ref={rsvpRef} className="main__rsvp">
        <div className="main__rsvp__img">
          <img
            className="web"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/qd_199.jpg`}
          />
          <img
            className="mobile"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/qd_199_mb.jpg`}
          />
        </div>
        <div className="main__rsvp__form">
          <div className="main__rsvp__form__bg-img">
            <img
              className="web"
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/img/bg.svg`}
            />
            <img
              className="mobile"
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/img/bg_mb.svg`}
            />
          </div>
          <div className="main__rsvp__form__content">
            <div
              className="main__rsvp__form__content--box"
              style={
                appState.platform === "web"
                  ? {
                      width:
                        status === "pending"
                          ? "28vw"
                          : status === "rejected"
                          ? "24vw"
                          : status === "accepted"
                          ? "32vw"
                          : "36vw",
                    }
                  : {
                      width:
                        status === "pending"
                          ? "64vw"
                          : status === "rejected"
                          ? "58vw"
                          : status === "accepted"
                          ? "68vw"
                          : "50vw",
                    }
              }
            >
              <div className="main__rsvp__form__content__title">
                {status === "pending" && t("12")}
                {status === "rejected" && t("56")}
                {status === "accepted" && (
                  <>
                    <div className="rsvp">{t("9")}</div>
                    <span>{t("20")}</span>
                  </>
                )}
                {status === "submitted" && (
                  <>
                    <div className="rsvp">{t("9")}</div>
                    <span>
                      {t("31")}
                      {appState.platform === "web" && <br />} {t("52")}
                    </span>
                  </>
                )}
              </div>
              {status === "pending" && (
                <div className="main__rsvp__form__content__info">
                  <div>
                    <div className="info-icon">
                      <img
                        alt=""
                        src={`${process.env.PUBLIC_URL}/assets/img/icon_ionic_ios_calendar.svg`}
                      />
                    </div>
                    <div className="info-description">{t("13")}</div>
                  </div>
                  <div>
                    <div className="info-icon">
                      <img
                        alt=""
                        src={`${process.env.PUBLIC_URL}/assets/img/icon_awesome_clock.svg`}
                      />
                    </div>
                    <div className="info-description">{t("14")}</div>
                  </div>
                  <div>
                    <div className="info-icon">
                      <img
                        alt=""
                        src={`${process.env.PUBLIC_URL}/assets/img/icon_material_place.svg`}
                      />
                    </div>
                    <div className="info-description">{t("15")}</div>
                  </div>
                </div>
              )}
              {status === "rejected" && (
                <div className="main__rsvp__form__content__sentence">
                  {t("18")}
                  <br />
                  <br />
                  {t("19")}
                </div>
              )}
              {status === "pending" && (
                <div className="main__rsvp__form__content__btn-group">
                  <div
                    className="form-btn"
                    onClick={(e) => onSubmitRsvp(e, "accepted")}
                  >
                    {t("16")}
                  </div>
                  <div
                    className="form-btn"
                    onClick={(e) => onSubmitRsvp(e, "rejected")}
                  >
                    {t("17")}
                  </div>
                </div>
              )}
              {status === "rejected" && (
                <div className="main__rsvp__form__content__btn-group">
                  <div
                    className="form-btn"
                    onClick={() => setStatus("pending")}
                  >
                    {t("54")}
                  </div>
                </div>
              )}
              {status === "accepted" && (
                <form
                  className="main__rsvp__form__content__form"
                  noValidate=""
                  autoComplete="off"
                >
                  <div className="grid">
                    <div className="form-field">
                      <label>{t("21")}</label>
                      <input
                        name="name"
                        type="text"
                        value={rsvpFormData.name}
                        onChange={handleRsvpFieldChange}
                        spellCheck={false}
                      />
                    </div>
                    <div className="form-field">
                      <label>{t("22")}</label>
                      <input
                        name="contactNo"
                        type="text"
                        value={rsvpFormData["contactNo"]}
                        onChange={handleRsvpFieldChange}
                        spellCheck={false}
                      />
                    </div>
                    <div className="form-field-tap">
                      <label>{t("23")}</label>
                      <div className="form-field-tap__content">
                        <div
                          className="minus-btn"
                          onClick={() => handleTotalPaxChange("minus")}
                        >
                          <img
                            alt=""
                            src={`${process.env.PUBLIC_URL}/assets/img/icon_feather_minus_circle.svg`}
                          />
                        </div>
                        <div className="magnitude">{pax}</div>
                        <div
                          className="plus-btn"
                          onClick={() => handleTotalPaxChange("add")}
                        >
                          <img
                            alt=""
                            src={`${process.env.PUBLIC_URL}/assets/img/icon_feather_plus_circle.svg`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-field-alt">
                      <label>{t("66")}</label>
                      <div className="radio-group">
                        <div className="radio-group__btn">
                          <input
                            type="radio"
                            value="Y"
                            checked={vegetarian === "Y"}
                            onChange={(e) => {
                              setVegetarian(e.target.value);
                            }}
                          />
                          <label>{t("68")}</label>
                        </div>
                        <div className="radio-group__btn">
                          <input
                            type="radio"
                            value="N"
                            checked={vegetarian === "N"}
                            onChange={(e) => {
                              setVegetarian(e.target.value);
                            }}
                          />
                          <label>{t("69")}</label>
                        </div>
                      </div>
                    </div>
                    {vegetarian === "Y" && appState.platform === "mobile" && (
                      <div className="form-field-tap">
                        <label>{t("61")}</label>
                        <div className="form-field-tap__content">
                          <div
                            className="minus-btn"
                            onClick={() => handleVegetarianPaxChange("minus")}
                          >
                            <img
                              alt=""
                              src={`${process.env.PUBLIC_URL}/assets/img/icon_feather_minus_circle.svg`}
                            />
                          </div>
                          <div className="magnitude">{vegetarianPax}</div>
                          <div
                            className="plus-btn"
                            onClick={() => handleVegetarianPaxChange("add")}
                          >
                            <img
                              alt=""
                              src={`${process.env.PUBLIC_URL}/assets/img/icon_feather_plus_circle.svg`}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-field-alt">
                      <label>{t("67")}</label>
                      <div className="radio-group">
                        <div className="radio-group__btn">
                          <input
                            type="radio"
                            value="Y"
                            checked={babyChair === "Y"}
                            onChange={(e) => {
                              setBabyChair(e.target.value);
                            }}
                          />
                          <label>{t("68")}</label>
                        </div>
                        <div className="radio-group__btn">
                          <input
                            type="radio"
                            value="N"
                            checked={babyChair === "N"}
                            onChange={(e) => {
                              setBabyChair(e.target.value);
                            }}
                          />
                          <label>{t("69")}</label>
                        </div>
                      </div>
                    </div>
                    {vegetarian === "Y" && appState.platform === "web" && (
                      <div className="form-field-tap">
                        <label>{t("61")}</label>
                        <div className="form-field-tap__content">
                          <div
                            className="minus-btn"
                            onClick={() => handleVegetarianPaxChange("minus")}
                          >
                            <img
                              alt=""
                              src={`${process.env.PUBLIC_URL}/assets/img/icon_feather_minus_circle.svg`}
                            />
                          </div>
                          <div className="magnitude">{vegetarianPax}</div>
                          <div
                            className="plus-btn"
                            onClick={() => handleVegetarianPaxChange("add")}
                          >
                            <img
                              alt=""
                              src={`${process.env.PUBLIC_URL}/assets/img/icon_feather_plus_circle.svg`}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-remarks">
                    <label>{t("28")}</label>
                    <textarea
                      name="remarks"
                      rows={5}
                      value={rsvpFormData.remarks}
                      maxLength={300}
                      onChange={handleRsvpFieldChange}
                      spellCheck={false}
                    />
                  </div>
                  <div className="form-legend">{t("29")}</div>
                  {Object.values(formError).includes(true) ||
                  Object.values(formError).includes(null) ? (
                    <div className="submit-btn disabled">{t("30")}</div>
                  ) : (
                    <div
                      className="submit-btn"
                      onClick={() => dispatch(showNotification())}
                    >
                      {t("30")}
                    </div>
                  )}
                </form>
              )}
              {status === "submitted" && (
                <div className="main__rsvp__form__content__reservation">
                  {/* <div className="table-no">
                    <div className="title">
                      {t("32")}
                      <div
                        className="info-icon"
                        onClick={() => scrollToSection(seatingLayoutRef)}
                      >
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/img/icon_ionic_ios_information_circle_outline.svg`}
                        />
                      </div>
                      <div className="no">{rsvpInfo.tableNo}</div>
                    </div>
                    <div className="value">
                      Your arranged table subject to change by the organiser.You
                      will be informed if your table number has been change.
                    </div>
                  </div> */}
                  <div className="grid">
                    <div>
                      <div className="title">{t("21")}</div>
                      <div className="value">{rsvpInfo.name}</div>
                    </div>
                    <div>
                      <div className="title">{t("22")}</div>
                      <div className="value">{rsvpInfo.contactNo}</div>
                    </div>
                    <div>
                      <div className="title">{t("23")}</div>
                      <div className="value">{rsvpInfo.pax}</div>
                    </div>
                    <div>
                      <div className="title">{t("25")}</div>
                      <div className="value">
                        {rsvpInfo.vegetarian === "Y" ? "Yes" : "No"}
                      </div>
                    </div>
                    <div>
                      <div className="title">{t("72")}</div>
                      <div className="value">{rsvpInfo.vegetarianPax}</div>
                    </div>
                    <div>
                      <div className="title">{t("73")}</div>
                      <div className="value">
                        {rsvpInfo.babyChair === "Y" ? "Yes" : "No"}
                      </div>
                    </div>
                    <div>
                      <div className="title">{t("58")}</div>
                      <div className="remarks value">{rsvpInfo.remarks}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="main__countdown">
        <div className="main__countdown__title">{t("74")}</div>
        <div className="main__countdown__timer">
          <CountdownTimer
            targetDate={new Date("2024-01-27T18:00:00").getTime()}
          />
        </div>
      </section>
      <section className="main__wedding-hall">
        <div className="main__wedding-hall__img">
          <img
            className="web"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/5833668487_f_5_cd_0_b_72133_cf_277_b_3_c_8_d_4.jpg`}
          />
          <img
            className="mobile"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/5833668487_f_5_cd_0_b_72133_cf_277_b_3_c_8_d_4_mb.jpg`}
          />
        </div>
      </section>
      <section ref={eventDetailsRef} className="main__event-details">
        <div className="main__event-details__title">{t("10")}</div>
        <div className="main__event-details__content">
          <div className="info">
            <div>
              <div className="info__icon">
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/assets/img/group_8.svg`}
                />
              </div>
              <div className="info__description">{t("60")}</div>
            </div>
            <div>
              <div className="info__icon">
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/assets/img/group_6.svg`}
                />
              </div>
              <div className="info__description">
                {t("15")}
                <br />
                <div className="address">
                  {`6, Jalan Stesen Sentral 2, Kuala Lumpur Sentral,\n50470 Kuala Lumpur, Malaysia.`}
                </div>
              </div>
            </div>
            <div>
              <div className="info__icon">
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/assets/img/group_7.svg`}
                />
              </div>
              <div className="info__description">{t("14")}</div>
            </div>
            <div className="map">
              <div className="info__icon">
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/assets/img/icon_awesome_waze.svg`}
                />
              </div>
              <div
                className="info__description"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleOpenNewTab("https://waze.com/ul/hw283cgp0h")
                }
              >
                The St. Regis
              </div>
            </div>
          </div>
          <div
            className="divider"
            style={
              appState.platform === "mobile"
                ? {
                    top: i18n.language === "cn" ? "42%" : "40%",
                  }
                : {}
            }
          ></div>
          {i18n.language === "cn" ? (
            <div className="agenda-img--cn">
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/assets/img/zh_agenda.png`}
              />
            </div>
          ) : (
            <div className="agenda-img--en">
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/assets/img/en_agenda.png`}
              />
            </div>
          )}
        </div>
      </section>
      {/* <section ref={seatingLayoutRef} className="main__seating-layout">
        <div className="main__seating-layout__img">
          <img alt="" src={`${process.env.PUBLIC_URL}/assets/img/seat.jpg`} />
        </div>
        <div className="main__seating-layout__description">
          <div className="title">{t("37")}</div>
          <div className="sentence">{t("38")}</div>
          <div
            className="full-screen"
            onClick={() =>
              handleOpenNewTab(
                `${process.env.PUBLIC_URL}/assets/img/seat.jpg`
              )
            }
          >
            <div className="full-screen__img">
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/assets/img/icon_map_fullscreen.svg`}
              />
            </div>
            <div className="full-screen__description">{t("39")}</div>
          </div>
        </div>
      </section> */}
      <section className="main__faq">
        <div ref={faqRef} className="main__faq__content">
          <div className="title">
            {t("11")}
            <span>{t("41")}</span>
          </div>
          <div className="body">
            <Accordions items={faqList} />
          </div>
          <div className="divider"></div>
          <div
            className="whatsapp"
            onClick={() => handleOpenNewTab("https://wa.me/60102127738")}
          >
            <div className="whatsapp__img">
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/assets/img/icon_awesome_whatsapp.svg`}
              />
            </div>
            <div className="whatsapp__desc">{t("62")}</div>
          </div>
        </div>
        <div className="main__faq__img">
          <img
            className="web"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/qd_70.jpg`}
          />
          <img
            className="web"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/qd_97.jpg`}
          />
          <img
            className="mobile"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/group_84.jpg`}
          />
        </div>
      </section>
      {notificationState.show && (
        <Notification
          onSubmit={(e) => onSubmitRsvp(e, "submitted")}
          value={{
            ...rsvpFormData,
            pax,
            vegetarian,
            vegetarianPax,
            babyChair,
          }}
        />
      )}
    </div>
  );
}

export default withAuth(Main);
