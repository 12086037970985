import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import "./style.scss";
import { useState } from "react";
import { useSelector } from "react-redux";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, appstate }) => ({
  backgroundColor: "transparent",
  marginBottom: appstate.platform === "web" ? "2vw" : "4vw",

  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <div className="accordions__expand-icon">
        <img
          alt=""
          src={`${process.env.PUBLIC_URL}/assets/img/collapse_icn.svg`}
        />
      </div>
    }
    {...props}
  />
))(({ theme, appstate }) => ({
  padding: appstate.platform === "web" ? "0 0 1.2vw 0" : "0 0 2vw 0",
  flexDirection: "row",
  borderBottom: "0.08vw solid #0d3b2b",
  minHeight: "unset",

  "& .MuiAccordionSummary-content": {
    margin: 0,
  },

  "& .MuiAccordionSummary-expandIconWrapper": {
    marginRight: "1.5vw",

    "&.Mui-expanded": {
      transform: "rotate(90deg)",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme, appstate }) => ({
  padding: appstate.platform === "web" ? "1vw 3vw 0 0" : "3.2vw 3.3vw 0 3.3vw",
}));

function Accordions(props) {
  const appState = useSelector((state) => state.app);
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="accordions">
      {props.items.map((item, index) => (
        <Accordion
          key={index}
          appstate={appState}
          expanded={expanded === `accordion${index}`}
          onChange={handleChange(`accordion${index}`)}
        >
          <AccordionSummary appstate={appState}>
            <div className="accordions__title">{item.title}</div>
          </AccordionSummary>
          <AccordionDetails appstate={appState}>
            <div className="accordions__body">{item.body}</div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default Accordions;
