const initState = {
  show: false,
  type: "",
  message: "",
};

const alertReducer = (state = initState, action) => {
  if (action.type === "UPDATE_ALERT_SHOW") {
    return {
      ...state,
      show: action.payload,
    };
  } else if (action.type === "UPDATE_ALERT_TYPE") {
    return {
      ...state,
      type: action.payload,
    };
  } else if (action.type === "UPDATE_ALERT_MESSAGE") {
    return {
      ...state,
      message: action.payload,
    };
  }

  return state;
};

export default alertReducer;
