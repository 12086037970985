const initState = {
  platform: window.innerWidth <= 768 ? "mobile" : "web",
  sessionExpired: false,
  isLoading: false,
  currentGuest: {
    username: "",
    status: "",
    token: "",
  },
};

const statusReducer = (state = initState, action) => {
  if (action.type === "UPDATE_IS_LOADING") {
    return {
      ...state,
      isLoading: action.payload,
    };
  } else if (action.type === "UPDATE_CURRENT_GUEST") {
    return {
      ...state,
      currentGuest: action.payload,
    };
  } else if (action.type === "UPDATE_SESSION_EXPIRED") {
    return {
      ...state,
      sessionExpired: action.payload,
    };
  } else if (action.type === "UPDATE_PLATFORM") {
    return {
      ...state,
      platform: action.payload,
    };
  }

  return state;
};

export default statusReducer;
