import { useState, useEffect } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";

function CountdownTimer({ targetDate }) {
  const { t } = useTranslation();

  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(targetDate)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(targetDate));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [targetDate]);

  function calculateTimeRemaining(targetDate) {
    const now = new Date();
    const timeRemaining = new Date(targetDate) - now;
    return timeRemaining > 0 ? timeRemaining : 0;
  }

  function formatTime(ms) {
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));
    const hours = Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    return [
      {
        label: t("75"),
        value: days,
      },
      {
        label: t("76"),
        value: hours,
      },
      {
        label: t("77"),
        value: minutes,
      },
      {
        label: t("78"),
        value: seconds,
      },
    ];
  }

  return (
    <div className="countdown-timer">
      {formatTime(timeRemaining).map((item, i) => (
        <div className="countdown-timer__item" key={i}>
          <div className="value">{item.value}</div>
          <div className="label">{item.label}</div>
        </div>
      ))}
    </div>
  );
}

export default CountdownTimer;
