const initState = {
  show: false,
};

const notificationReducer = (state = initState, action) => {
  if (action.type === "UPDATE_NOTIFICATION_SHOW") {
    return {
      ...state,
      show: action.payload,
    };
  }

  return state;
};

export default notificationReducer;
