import { useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Spinner from "./components/Spinner";
import {
  deleteCookie,
  deleteLocalStorage,
  getCookie,
  getLocalStorage,
} from "./utils/general";
import { useDispatch, useSelector } from "react-redux";
import { updatePlatform } from "./redux/actions/appAction";
import Main from "./pages/Main";
import ImagePreloader from "./ImagePreloader";

const tokenName = `${process.env.REACT_APP_ENVIRONMENT}qdweddingguest`;

function App() {
  const dispatch = useDispatch();
  const appRef = useRef(null);
  const appState = useSelector((state) => state.app);
  const [isLoading, setIsLoading] = useState(true);

  const handleStorageChange = (event) => {
    if ((event.key === tokenName || !event.key) && !event.newValue) {
      deleteCookie(tokenName);
      deleteLocalStorage(tokenName);
      window.location.replace("/login");
    }
  };

  const handleSizeChange = () => {
    let platform = "web";

    if (window.innerWidth <= 768) {
      platform = "mobile";
    }

    dispatch(updatePlatform(platform));
  };

  useEffect(() => {
    if (["/login"].includes(window.location.pathname)) {
      const sessionToken = getLocalStorage(tokenName) || getCookie(tokenName);

      if (sessionToken) window.location.replace("/");
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);

    window.addEventListener("resize", handleSizeChange);

    return () => {
      window.removeEventListener("resize", handleSizeChange);
      window.addEventListener("storage", handleStorageChange);
    };
  }, [appState]);

  const imagesToPreload = [
    "dq_bg_1.jpg",
    "dq_bg_1_mb.jpg",
    "qd_118_mb.jpg",
    "qd_118.jpg",
    "qd_151_mb.jpg",
    "qd_151.jpg",
    "dq_logo.svg",
    "dq_logo_2.svg",
    "dq_bg_2.svg",
  ];

  return (
    <div className="App" ref={appRef}>
      <ImagePreloader images={imagesToPreload} />
      {!isLoading && (
        <>
          <Routes>
            <Route exact path="/" element={<Main />} />
            <Route exact path="/login" element={<Login />} />
          </Routes>
          <Spinner />
        </>
      )}
    </div>
  );
}

export default App;
