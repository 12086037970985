import Cookies from "js-cookie";

export const getLocalStorage = (name) => {
  const token = localStorage.getItem(name);

  return token;
};

export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, value);

  return true;
};

export const deleteLocalStorage = (name) => {
  localStorage.removeItem(name);

  return true;
};

export const emailStandard = (email) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  return emailRegex.test(email);
};

export const capitalizeFirstChar = (chars) => {
  return chars.charAt(0).toUpperCase() + chars.slice(1);
};

export const setCookie = (name, value) => {
  Cookies.set(name, value, { expires: 180 });

  return true;
};

export const getCookie = (name) => {
  const cookie = Cookies.get(name);

  return cookie;
};

export const deleteCookie = (name) => {
  Cookies.remove(name);

  return true;
};
