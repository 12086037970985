export const showNotification = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_NOTIFICATION_SHOW",
      payload: true,
    });
  };
};

export const closeNotification = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_NOTIFICATION_SHOW",
      payload: false,
    });
  };
};
