import React, { useEffect, useState } from "react";
import {
  deleteCookie,
  deleteLocalStorage,
  getCookie,
  getLocalStorage,
} from "../utils/general";
import { verifySessionApi } from "../utils/api";

const tokenName = `${process.env.REACT_APP_ENVIRONMENT}qdweddingguest`;

const withAuth = (WrappedComponent) => {
  const AuthComponent = (props) => {
    const [isDone, updateIsDone] = useState(false);

    useEffect(() => {
      const sessionToken = getLocalStorage(tokenName) || getCookie(tokenName);

      if (!sessionToken) window.location.replace("/login");

      verifySessionApi().then((res) => {
        if (!res.data) {
          deleteCookie(tokenName);
          deleteLocalStorage(tokenName);
          window.location.replace("/login");
        }

        updateIsDone(true);
      });
    }, []);

    return isDone ? <WrappedComponent {...props} /> : <></>;
  };

  return AuthComponent;
};

export default withAuth;
